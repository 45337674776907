$(document).ready(function()
{
    $('body').placeholderFix().browserNotice().backToTop();
    $('.tapHover').on('touchend', tapHover);
    $('.accHandler').accordion({openFirst:true});
    $('a.escape').noSpam();
    $('form[data-validate="true"]').each(function() {
        $(this).validate();
    });
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });
    $('a:external').attr('target', '_blank');
});
